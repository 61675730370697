<template>
  <v-menu
    v-model="menu"
    offset-y
    content-class="edit-menu"
    :disabled="shareLink"
    :close-on-content-click="false"
    nudge-bottom="2">
    <template #activator="{ on }">
      <div
        class="d-flex"
        v-on="on">
        <IconTemplate
          class="mr-5"
          :icon-id="projectIconId ? `Icon_template_${projectIconId}` : undefined"
          :color="projectColor" />
      </div>
    </template>
    <v-card
      class="pa-0"
      min-height="230"
      width="240">
      <v-card-title
        class="d-flex justify-space-between"
        style="border-bottom: 1px solid lightgrey">
        <span
          v-for="color in pallete.default_colors"
          :key="color.uuid"
          class="color-unit d-flex justify-center pointer"
          :style="{ background: color.hex }"
          @click="setColorIcon({
            projectId: $route.params.id,
            colorId: color.uuid,
          })">
          <v-icon
            v-if="projectColor === color.hex"
            x-small
            color="white">
            mdi-check
          </v-icon>
        </span>
      </v-card-title>
      <v-card-text
        class="pa-0">
        <v-row class="px-5 pt-5">
          <v-col
            v-for="icon in pallete.default_icons"
            :key="icon.uuid"
            cols="4"
            class="pa-2 d-flex align-center justify-center"
            @click="setColorIcon({
              projectId: $route.params.id,
              iconId: icon.uuid,
            })">
            <IconTemplate
              :color="projectIconId === icon.uuid ? projectColor : undefined"
              :icon-id="`Icon_template_${icon.uuid}`" />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-menu>
</template>
<script>
import {
  mapActions, mapMutations, mapGetters,
} from 'vuex';
import ColoredIconsApi from '@/services/graphql/coloredIcons';
import IconTemplate from './ProjectDetailsSetColoredIconTemplate';
export default {
  name: 'ProjectDetailsSetColoredIcon',
  components: {
    IconTemplate,
  },
  props: {
    shareLink: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      menu: false,
      pallete: {
        default_colors: [],
        default_icons: [],
      },
    };
  },
  computed: {
    ...mapGetters('ProjectDetails', ['projectColor', 'projectIconId']),
  },
  watch: {
    menu(val) {
      if (val) {
        this.getColorIconPalette();
      }
    },
  },
  methods: {
    ...mapMutations(['spinner']),
    ...mapActions({
      handleError: 'handleError',
      setColorIcon: 'ProjectDetails/setColorIcon',
    }),
    async getColorIconPalette() {
      this.spinner(true);
      try {
        const { data } = await ColoredIconsApi.getColorIconPalette();
        this.pallete = data.response;
      } catch (err) {
        this.handleError(err);
      } finally {
        this.spinner(false);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
  .color-unit {
    width: 20px;
    height: 20px;
    border-radius: 4px;
    &:hover {
      opacity: 0.9;
    }
  }
</style>
